import React from "react"

export default function GalleryPage() {
  return <div></div>
}
// import React, { Component } from "react"
// import styled from "styled-components"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import Fade from "react-reveal/Fade"
// import PageHeader from "../components/page-header"
// import TabBarWithRender from "../components/TabBarWithRender"
// import { graphql } from "gatsby"

// const TabContainer = styled.div`
//   max-width: 1170px;
//   margin: 40px auto;
// `
// const MainContainer = styled.div`
//   display: flex;
//   padding: 50px 20px;
//   flex-direction: column;
//   align-items: center;
//   justify-content: flex-start;
// `

// const GalleryContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   justify-content: center;
//   display: grid;
//   margin-bottom: 50px;
//   grid-template-columns: repeat(3, 1fr);

//   @media (max-width: 550px) {
//     grid-template-columns: repeat(1, 1fr);
//   }

//   @media (max-width: 1200px) {
//     flex-wrap: wrap;
//   }

//   @media (max-width: 700px) {
//     flex-direction: column;
//   }
// `
// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin: 0px 10px;
//   flex: 1;
// `
// const Avatar = styled.img`
//   width: 350px;
//   object-fit: cover;
//   height: 400px;

//   @media (max-width: 700px) {
//     height: 300px;
//     width: 250px;
//   }
// `
// class Gallery extends Component {
//   constructor(props) {
//     super(props)
//     this.faqRef = React.createRef()

//     this.state = {}
//   }

//   handleLearnMore = () => {
//     this.faqRef.current.scrollIntoView({
//       behavior: "smooth",
//       block: "start",
//     })
//   }

//   render() {
//     console.log(this.props.data)
//     const gallery = this.props.data?.wordpressPage?.acf?.gallery
//     return (
//       <Layout>
//         <SEO
//           title="Gallery"
//           description="Many people want straighter teeth, but they don’t want the ugly metal brackets and the discomfort that comes with traditional orthodontic work. If you fit this description, you may want to consider Invisalign."
//         />
//         <PageHeader image={require("../images/invisalign.jpg")} />
//         <TabContainer>
//           <TabBarWithRender
//             tabs={[
//               {
//                 title: "All",
//                 render: () => (
//                   <MainContainer>
//                     <GalleryContainer>
//                       <Fade bottom distance="20px">
//                         {gallery?.map((item, index) => (
//                           <Container key={index}>
//                             <Avatar src={item.image.source_url} />
//                           </Container>
//                         ))}
//                       </Fade>
//                     </GalleryContainer>
//                   </MainContainer>
//                 ),
//               },
//               {
//                 title: "Bonding",
//                 render: () => (
//                   <MainContainer>
//                     <GalleryContainer>
//                       <Fade bottom distance="20px">
//                         {bondingImages.map((item, index) => (
//                           <Container key={index}>
//                             <Avatar src={item.avatar} />
//                           </Container>
//                         ))}
//                       </Fade>
//                     </GalleryContainer>
//                   </MainContainer>
//                 ),
//               },
//               {
//                 title: "Filling",

//                 render: () => (
//                   <MainContainer>
//                     <GalleryContainer>
//                       <Fade bottom distance="20px">
//                         {fillingImages.map((item, index) => (
//                           <Container key={index}>
//                             <Avatar src={item.avatar} />
//                           </Container>
//                         ))}
//                       </Fade>
//                     </GalleryContainer>
//                   </MainContainer>
//                 ),
//               },
//               {
//                 title: "Whitening",
//                 render: () => (
//                   <MainContainer>
//                     <GalleryContainer>
//                       <Fade bottom distance="20px">
//                         {whiteningImages.map((item, index) => (
//                           <Container key={index}>
//                             <Avatar src={item.avatar} />
//                           </Container>
//                         ))}
//                       </Fade>
//                     </GalleryContainer>
//                   </MainContainer>
//                 ),
//               },
//               {
//                 title: "Cosmetic",
//                 render: () => (
//                   <MainContainer>
//                     <GalleryContainer>
//                       <Fade bottom distance="20px">
//                         {cosmeticImages.map((item, index) => (
//                           <Container key={index}>
//                             <Avatar src={item.avatar} />
//                           </Container>
//                         ))}
//                       </Fade>
//                     </GalleryContainer>
//                   </MainContainer>
//                 ),
//               },
//               {
//                 title: "Invisalign",
//                 render: () => (
//                   <MainContainer>
//                     <GalleryContainer>
//                       <Fade bottom distance="20px">
//                         {invisalignImages.map((item, index) => (
//                           <Container key={index}>
//                             <Avatar src={item.avatar} />
//                           </Container>
//                         ))}
//                       </Fade>
//                     </GalleryContainer>
//                   </MainContainer>
//                 ),
//               },
//             ]}
//           />
//         </TabContainer>
//       </Layout>
//     )
//   }
// }

// export default Gallery

// export const invisalignGalleryQuery = graphql`
//   query MyQuery {
//     wordpressPage(id: { eq: "7c59dfac-7b1e-5673-af5b-960aa2fc3fc1" }) {
//       acf {
//         gallery {
//           type
//         }
//       }
//     }
//   }
// `

// const galleryImages = [
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
// ]

// const bondingImages = [
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
// ]
// const fillingImages = [
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
// ]
// const whiteningImages = [
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
// ]
// const cosmeticImages = [
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
// ]
// const invisalignImages = [
//   {
//     avatar: require("../images/team/nicole.jpg"),
//   },
//   {
//     avatar: require("../images/team/celine.jpg"),
//   },
//   {
//     avatar: require("../images/team/sachi.jpg"),
//   },
//   {
//     avatar: require("../images/team/jenelle.jpg"),
//   },
//   {
//     avatar: require("../images/team/shen.jpg"),
//   },
// ]
